import React from "react"

import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faRocket, faHeart, faSmile, faUserAstronaut, faCodeBranch, faFighterJet } from '@fortawesome/free-solid-svg-icons'

import Techstack from "../components/techstack/techstack"

import "./index.scss"
import "./careers.scss"
import "./contact.scss"

import VideoBg from "../videos/ftrs-about-us.mp4"

const Careers = () => (

    <Layout>
        <SEO title="Careers" description="We keep growing and are always happy to welcome new Rockstars on board. Let's have a chat about possible collaboration :)" />

		<div className="ftrs-background" data-sal="fade" data-sal-duration="2000">
			<video autoPlay="autoplay" muted loop className="ftrs-hero__video ftrs-hero__video--dark">
				<source media="(orientation: landscape)" src={VideoBg} type="video/mp4" />
			</video>
		</div>

        <Container className="ftrs-content pb-4">

            <h1 data-sal="fade" data-sal-duration="1000" className="ftrs-h1">Become a&nbsp;Rockstar</h1>

            <Row>

                <Col xs="12" lg="7" className="ftrs-career pb-3">
                    <h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 mt-4 mb-5"><span role="img" aria-label="flash">⚡</span> PHP/Nette developer <span role="img" aria-label="rocket">🚀</span><span className="ftrs-career__hot">HOT</span></h2>
                    
                    <h3 data-sal="fade" data-sal-duration="1000" className="ftrs-h3"><span role="img" aria-label="info">ℹ️</span> Briefly about us:</h3>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Our development team creates, improves and&nbsp;maintains our own <Link to="/our-work">web and&nbsp;mobile apps</Link>.</p>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Our apps are used by&nbsp;market leaders across multiple industries - mainly automotive, finance, telco, and&nbsp;insurance. Our&nbsp;customers include Vodafone, T-Mobile, O2, Airbank, Česká&nbsp;spořitelna, Uniqa, Toyota, Harley-Davidson and&nbsp;many&nbsp;more.</p>

                    <h3 data-sal="fade" data-sal-duration="1000" className="ftrs-h3 mt-4"><span role="img" aria-label="superman">🦸‍♂️</span> Join our growing team:</h3>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">For this position we are looking for&nbsp;a&nbsp;rather experienced developer. Currently we are moving our apps to&nbsp;the&nbsp;cloud with the&nbsp;help of&nbsp;Kubernetes.</p>

                    <h3 data-sal="fade" data-sal-duration="1000" className="ftrs-h3 mt-4"><span role="img" aria-label="cleverman">🤓</span> We are looking for someone with knowledge of:</h3>
                    <ul data-sal="fade" data-sal-duration="1000" className="text-basic">
                        <li>PHP</li>
                        <li>Nette</li>
                        <li>Mysql</li>
                        <li>Git</li>
                    </ul>

                    <h3 data-sal="fade" data-sal-duration="1000" className="ftrs-h3 mt-4"><span role="img" aria-label="rocket">🚀</span> Knowing this would be great but not mandatory:</h3>
                    <ul data-sal="fade" data-sal-duration="1000" className="text-basic">
                        <li>Docker</li>
                        <li>Elasticsearch</li>
                        <li>DevOps (AWS, Kubernetes)</li>
                        <li>Angular</li>
                    </ul>

                    <h3 data-sal="fade" data-sal-duration="1000" className="ftrs-h3 mt-4"><span role="img" aria-label="sparkling">✨</span> What we offer:</h3>
                    <ul data-sal="fade" data-sal-duration="1000" className="text-basic">
                        <li>work on interesting products for even more interesting companies with&nbsp;huge amounts of&nbsp;users and&nbsp;a&nbsp;real impact</li>
                        <li>regular educational events</li>
                        <li>friendly environment including an&nbsp;office with air&nbsp;conditioning and&nbsp;a&nbsp;terrace</li>
                        <li>possibility to&nbsp;work from home and/or remote work</li>
                        <li>adequate salary</li>
                        <li>events, offsites, education, benefits, and&nbsp;more</li>
                        <li>company sailboat available to&nbsp;team members <span role="img" aria-label="boat">⛵</span></li>
                    </ul>

                    <h3 data-sal="fade" data-sal-duration="1000" className="ftrs-h3 mt-4"><span role="img" aria-label="thinking">🤔</span> What apps you'll be working on:</h3>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">The best and&nbsp;latest apps for&nbsp;market research. The are used to&nbsp;manage mystery shoppings, audits, hiring, rewards, data collection, validation, and&nbsp;reporting. Through API they communicate with mobile apps. One&nbsp;big project is in&nbsp;our sights- the&nbsp;reward module.</p>
                </Col>

                <Col xs="12" lg="5" className="mt-3 pb-3">
                    <div className="ftrs-career__sticky">
                        <blockquote data-sal="fade" data-sal-duration="1000" className="ftrs-contact__box ftrs-contact__box--careers">
							We are excited to&nbsp;grow our team with&nbsp;excellent and&nbsp;ambitious rockstars!<br />Please send your CV to&nbsp;Jarda <span role="img" aria-label="pointing down">👇</span>
                        </blockquote>
                        <div data-sal="fade" data-sal-duration="1000" className="ftrs-contact__box ftrs-contact__box--careers2">
                            <p className="ftrs-contact__name">
                                Jaroslav Suchý
                            </p>
                            <p className="ftrs-contact__email">
                                <a href="mailto:jarda@futurerockstars.cz"><FontAwesomeIcon icon={faEnvelope} /> jarda@futurerockstars.cz</a><br />
                            </p>
                            <p className="ftrs-contact__phone">
                                <a href="tel:+420721765529"><FontAwesomeIcon icon={faPhone} /> +420 721 765 529</a>
                            </p>
                        </div>
                    </div>
                </Col>

            </Row>

            <p data-sal="fade" data-sal-duration="1000" className="ftrs-h2 mt-5 mb-5">Why Future Rockstars?</p>

            <Row>

                <Col xs="12" lg="4" className="ftrs-career pb-4">
                    <div className="ftrs-career__list-item" data-sal="fade" data-sal-duration="1000">
                        <div className="ftrs-career__icon-container">
                            <FontAwesomeIcon icon={faRocket} className="ftrs-career__icon ftrs-career__icon--color1" />
                        </div>
                        <div className="ftrs-career_text">
                            <h3 className="ftrs-h3">Development</h3>
                            <p className="text-basic">We love learning and&nbsp;pushing our limits while exploring the&nbsp;possibilities and&nbsp;application of&nbsp;new technology.</p>
                        </div>
                    </div>
                </Col>

                <Col xs="12" lg="4" className="ftrs-career pb-4">
                    <div className="ftrs-career__list-item" data-sal="fade" data-sal-duration="1000">
                        <div className="ftrs-career__icon-container">
                            <FontAwesomeIcon icon={faHeart} className="ftrs-career__icon ftrs-career__icon--color2" />
                        </div>
                        <div className="ftrs-career_text">
                            <h3 className="ftrs-h3">Flexibility</h3>
                            <p className="text-basic">Our development team maintains close relationships with&nbsp;our customers and&nbsp;our processes can be adjusted to&nbsp;meet almost any need.</p>
                        </div>
                    </div>
                </Col>

                <Col xs="12" lg="4" className="ftrs-career pb-4">
                    <div className="ftrs-career__list-item" data-sal="fade" data-sal-duration="1000">
                        <div className="ftrs-career__icon-container">
                            <FontAwesomeIcon icon={faFighterJet} className="ftrs-career__icon ftrs-career__icon--color3" />
                        </div>
                        <div className="ftrs-career_text">
                            <h3 className="ftrs-h3">Traction and&nbsp;track record</h3>
                            <p className="text-basic">We continuously deliver our solutions to&nbsp;companies like Audi, Toyota, <span className="nobr">T-Mobile</span>, Airbank and&nbsp;many more.</p>
                        </div>
                    </div>
                </Col>

                <Col xs="12" lg="4" className="ftrs-career pb-4">
                    <div className="ftrs-career__list-item" data-sal="fade" data-sal-duration="1000">
                        <div className="ftrs-career__icon-container">
                            <FontAwesomeIcon icon={faSmile} className="ftrs-career__icon ftrs-career__icon--color4" />
                        </div>
                        <div className="ftrs-career_text">
                            <h3 className="ftrs-h3">Your input matters</h3>
                            <p className="text-basic">About 100.000 users interact with our products monthly.</p>
                        </div>
                    </div>
                </Col>

                <Col xs="12" lg="4" className="ftrs-career pb-4">
                    <div className="ftrs-career__list-item" data-sal="fade" data-sal-duration="1000">
                        <div className="ftrs-career__icon-container">
                            <FontAwesomeIcon icon={faUserAstronaut} className="ftrs-career__icon ftrs-career__icon--color5" />
                        </div>
                        <div className="ftrs-career_text">
                            <h3 className="ftrs-h3">Strong background</h3>
                            <p className="text-basic">Future Rockstars is a&nbsp;member of&nbsp;a&nbsp;strong holding with 15+ years of&nbsp;experience in&nbsp;market monitoring and&nbsp;analysis, as&nbsp;well as&nbsp;customer experience.</p>
                        </div>
                    </div>
                </Col>

                <Col xs="12" lg="4" className="ftrs-career pb-4">
                    <div className="ftrs-career__list-item" data-sal="fade" data-sal-duration="1000">
                        <div className="ftrs-career__icon-container">
                            <FontAwesomeIcon icon={faCodeBranch} className="ftrs-career__icon ftrs-career__icon--color6" />
                        </div>
                        <div className="ftrs-career_text">
                            <h3 className="ftrs-h3">Passionate contributors</h3>
                            <p className="text-basic">We love to&nbsp;share our knowledge! Therefore, we&nbsp;participate in&nbsp;webinars and&nbsp;release code to&nbsp;github for&nbsp;the&nbsp;open source community. We are a&nbsp;proud <a href="https://nette.org/cs/donate" target="_blank" rel="noreferrer">PHP Nette bronze partner</a>.</p>
                        </div>
                    </div>
                </Col>

            </Row>

        </Container>

        <Techstack></Techstack>

    </Layout>

)

export default Careers